body[data-theme="light"] {
  @import "../src/scss/light-slider.scss";

  .slider-tab {
    background-color: #d7caaa;
  }

  #about {
    h1.section-title {
      color: black;
    }
  }
}
